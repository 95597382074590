<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import { required, minLength, email, requiredIf,sameAs } from 'vuelidate/lib/validators';
const user = JSON.parse(localStorage.getItem('currentUser')) ;
export default {
    page: {
        title: "Profil "+user.fullName,
        meta: [{ name: "description", content: appConfig.description}],
    },
    components: { Layout, PageHeader },
    
    data() {
        return {
            info_user:{
                fullName:'',
                username:'',
                email:'',
                account_typeObj:'',
                structType:'',
                id_number:'',
                lifebase:'',
                direction:'',
                division:'',
                department:'',
                service:'',
            },
            user_account:{
                uuid:'',
                Email:'',
                Password:'',
                Password_confirmation:''
            },
            
            title: "Profil "+user.fullName,
            items: [
                {
                text: "Profil Utilisateur",
                active: true
                }
            ],
        };
    },
    created(){
        this.getUserInfo();
    },
    methods:{
        formSubmit(){
            this.$v.$touch()
            if (this.$v.$invalid) {
                console.log("ERROR");
                this.$toast.warning('Vos Informations sont invalide,Veuillez les Vérifier!!');
                this.submitted = false;
            }
            else {
                this.submitted = false;
            
                Swal.fire({
                    title: "Êtes-vous sûr de modifier votre compte ?",
                    text: "Vous ne pourrez pas annuler cela!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Modifier!",
                }).then((result) => {
                    if (result.value) {
                    this.$http
                        .post('/system/users/updateSingle',this.user_account)
                        .then((res) => {
                        var status = res.data.original.status;
                        switch (status) {
                            case 200:
                            Swal.fire('Modification Réussi',res.data.original.msg+'. Votre compte est à jour','success')
                            this.getUserInfo()
                            break;

                            case 500:
                            Swal.fire('une erreur s\'est produite',res.data.original.msg,'error')
                            this.$toast.warning(res.data.original.msg);
                            break;
                        }
                        })
                        .catch((error) => {
                        this.$toast.error(error.message);
                        })
                        .finally(() => {});
                    }
                });
            }
        },
        getUserInfo(){
            this.$http.post('system/users/getSingleUserData/'+user.uuid)
            .then(response => {
                this.info_user=response.data.data;
                this.info_user.fullName=response.data.data.first_name +" "+response.data.data.last_name;
                this.user_account.uuid = this.info_user.uuid;
                this.user_account.Email = this.info_user.email;

            })
            .catch(error => console.log(error))
        }
    },
    validations:{
        user_account:{
            Email:{
                required,
                email
            },
            Password:{
                required,
                minLength:minLength(6)
            },
            Password_confirmation:{
                required:requiredIf(function() {
                    return this.user_account.Password;
                }),
                sameAsPassword: sameAs('Password')

            }

        }
    },
    
}
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-3">User Profile's</h4>
                    <!-- <p class="card-title-desc">Example of Vertical nav tabs</p> -->
                    <b-tabs
                    pills
                    vertical
                    nav-class="p-0"
                    nav-wrapper-class="col-sm-3"
                    content-class="pt-0 px-2 text-muted"
                    >
                        <b-tab title="Informations Générales" active title-item-class="mb-2">
                            <b-card-text>
                                <div class="row">
                                    <div class="col-12">
                                        <h6>YOUR PROFILE INFORMATION</h6>
                                        <hr>
                                        <form>
                                        <div class="form-group">
                                            <div class="row">
                                                
                                                <div class="col-md-3">
                                                    <label for="fullName">Nom Complet</label>
                                                    <input type="text" disabled class="form-control bg-light" id="fullName" aria-describedby="fullNameHelp" placeholder="Enter your fullname" v-model="info_user.fullName">
                                                    <!-- <small id="fullNameHelp" class="form-text text-muted">Si vous remarquez une erreur veuillez s'il vous plait le signaler à la direction.</small> -->
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="fullName">Sexe</label>
                                                    <input type="text" disabled class="form-control bg-light" id="fullName" aria-describedby="fullNameHelp" placeholder="Enter your fullname" value="H">
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="">Email</label>
                                                    <input type="email" class="form-control bg-light" v-model="info_user.email" disabled>
                                                </div>
                                                <div v-if="info_user.employee"  class="col-md-3">
                                                    <label for="">Téléphone</label>
                                                    <input type="text" class="form-control bg-light" v-model="info_user.employee.phone" disabled>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <!-- <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label for="">Email</label>
                                                    <input type="email" class="form-control bg-light" v-model="info_user.email" disabled>
                                                </div>
                                                <div v-if="info_user.employee"  class="col-md-4">
                                                    <label for="">Téléphone</label>
                                                    <input type="text" class="form-control bg-light" v-model="info_user.employee.phone" disabled>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label for="fullName">Base de vie</label>
                                                    <input type="text" disabled class="form-control bg-light" v-model="info_user.lifebases">
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="fullName">Matricule</label>
                                                    <input type="text" disabled class="form-control bg-light" v-model="info_user.id_number" >
                                                </div>
                                                <div v-if="info_user.account_typeObj.id == 'employee'" class="col-md-3">
                                                    <label for="fullName">Structure</label>
                                                    <input type="text" disabled class="form-control bg-light" v-model="info_user.structType.name"  value="Moyens Généraux">
                                                </div>
                                                <div v-if="info_user.account_typeObj.id == 'supplier_staff'" class="col-md-3">
                                                    <label for="fullName">Prestataire</label>
                                                    <input type="text" disabled class="form-control bg-light" v-model="info_user.supplier.prestname"  value="Moyens Généraux">
                                                </div>
                                                <div v-if="info_user.employee" class="col-md-3">
                                                    <label for="fullName">Poste</label>
                                                    <input type="text" disabled class="form-control bg-light" v-model="info_user.employee.postTitle"  value="Directeur Général">
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <!-- <div class="form-group">
                                            <label for="location">Location</label>
                                            <input type="text" class="form-control" id="location" placeholder="Enter your location" value="Bay Area, San Francisco, CA">
                                        </div> -->
                                        <div class="form-group small text-muted">
                                            Si vous remarquez une erreur, veuillez s'il vous plaît le signaler à la direction.
                                        </div>
                                        <!-- <button type="button" class="btn btn-primary">Update Profile</button>
                                        <button type="reset" class="btn btn-light">Reset Changes</button> -->
                                        </form>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Compte Utilisateur" title-item-class="mb-2">
                            <b-card-text>
                                <div class="row mb-2">
                                    <div class="col-md-6">
                                        <label for="fullName">Nom Utilisateur</label>
                                        <input type="text" disabled class="form-control bg-light" v-model="info_user.username">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="fullName">Email</label>
                                        <input type="email" :class="{'is-invalid': $v.user_account.Email.$error}"  class="form-control" v-model="user_account.Email" >
                                        <!-- <small id="fullNameHelp" class="form-text text-muted">Si vous remarquez une erreur veuillez s'il vous plait le signaler à la direction.</small> -->
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="">Mot de epasse</label>
                                        <input type="password" class="form-control" :class="{'is-invalid': $v.user_account.Password.$error}"  v-model="user_account.Password">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">Confirmation Mot de Passe</label>
                                        <input type="password" class="form-control" :class="{'is-invalid': $v.user_account.Password_confirmation.$error}" v-model="user_account.Password_confirmation">
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col text-right">
                                        <button type="button" class="btn btn-primary" @click="formSubmit"><i class="mdi mdi-content-save font-size-16 align-middle me-2"></i> Enregirster</button>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <!-- <b-tab title="Messages" title-item-class="mb-2">
                            <b-card-text>{{ text }}</b-card-text>
                        </b-tab>
                        <b-tab title="Settings">
                            <b-card-text>{{ content }}</b-card-text>
                        </b-tab> -->
                    </b-tabs>
                </div>
                </div>
            </div>
            
        </div>
    </Layout>
</template>